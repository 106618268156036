<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                title="Incentive Setup"
                btnTitle="Add New"
                :showAddNew="true"
                @onClickAddNewButton="onClickAddNewButton"
            />
        </div>
        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickToGo"
                class="tds-vds-query-setter"
            >
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label for="colFormLabel" class="col-form-label">Incentive Type</label>
                <v-select
                    placeholder="Select Incentive Type"
                    v-model="formData.incentive_type"
                    :options="incentiveType"
                    :reduce="name => name.value"
                    label="name"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label for="colFormLabel" class="col-form-label">Status</label>
                <v-select
                    placeholder="Select Status"
                    v-model="formData.status"
                    :options="status"
                    label="name"
                    :reduce="name => name.value"
                />
              </div>
            </DateQuerySetter>
        </div>

        <div class="col-12 px-2 mt-2 mb-4">
            <ListTable
                :tableItems="tableItems"
            />
        </div>

        <div class="px-2 position-absolute bottom-0 mt-4">
            <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <Loader v-if="loading"/>

    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import { inject, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import Pagination from '@/components/atom/Pagination'
import ListTable       from '@/components/molecule/company/hospital/incentive-setup/ListTable.vue'
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
import { computed } from 'vue';
import handleInventory from "@/services/modules/inventory";
import handleHospital from "@/services/modules/hospital";

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const tableItems = ref([]);
const status = ref([]);
const incentiveType = ref([]);
const params = ref({
    offset: 30,
});
let formData = ref({
  status: 'active',
  incentive_type:'',
})

const token = computed(() => localStorage.getItem('token'));

const { fetchIncentiveSetupList } = handleHospital()

const { fetchHome } = handleInventory()
const onClickToGo = () => {
   getIncentiveSetupList();
}

const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

function onClickAddNewButton() {
  router.push({name: `incentive-setup-add`, params: route.params, query: route.query})
}

const onPageChange = async (page) => {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    await router.push({path: route.path, query: routeQuery})
    await getIncentiveSetupList();
}
const getIncentiveSetupList = async () => {
    const query = getQuery();
    loading.value = true
    tableItems.value = []

    await fetchIncentiveSetupList(query).then(res => {
        if (!res.status){
            store.commit("resetPagination")
            return
        }

        tableItems.value = res.data.data;
        setPagination(res.data)
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const getTypes = async () => {
  let query = getQuery();
  await fetchHome(query).then(res => {
    if (!res.status){
      status.value = [];
      incentiveType.value = [];
      return
    }
    if (res.data && res.data.status) {
      status.value = Object.keys(res.data.status)
          .filter(key => ["active", "inactive"].includes(key))
          .map(key => ({
            value: key,
            name: res.data.status[key]
          }));
    }
    if (res.data && res.data.incentive_type) {
      incentiveType.value = Object.keys(res.data.incentive_type)
          .map(key => ({
            value: key,
            name: res.data.incentive_type[key]
          }));
    }

  }).catch(err => {
    console.log(err)
  })
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if (route.query.page) query += '&page=' + route.query.page;
    if (route.query.start) query += '&start_date=' + route.query.start;
    if (route.query.end) query += '&end_date=' + route.query.end;
    if (formData.value.incentive_type) query += '&incentive_type=' + formData.value.incentive_type;
    if (formData.value.status) query += '&status=' + formData.value.status;
    query += '&offset=' + params.value.offset
    return query;
}

onMounted(() => {
    Promise.all([
       getIncentiveSetupList(),
       getTypes()
    ])
})
</script>
